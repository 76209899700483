import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NavbarComponent } from "./school/components/navbar/navbar.component";
import { SidebarComponent } from "./school/components/admin-sidebar/sidebar.component";
import { FooterComponent } from "./school/components/footer/footer.component";
import { ClearFiltersComponent } from './school/components/clear-filters/clear-filters.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { EditCurriculumComponent } from './school/components/edit-curriculum/edit-curriculum.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { BsDatepickerConfig, BsDatepickerModule } from "ngx-bootstrap/datepicker/";
import { SideDrawerComponent } from './school/components/side-drawer/side-drawer.component';

@NgModule({
  declarations: [NavbarComponent, SidebarComponent, FooterComponent, ClearFiltersComponent, EditCurriculumComponent, SideDrawerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxDropzoneModule,
    AngularEditorModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    ClearFiltersComponent,
    EditCurriculumComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxDropzoneModule,
    AngularEditorModule,
    HttpClientModule,
    BsDatepickerModule,
    SideDrawerComponent
  ],
})

export class SharedComponentsModule {}

      
    
