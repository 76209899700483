import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  
  {
    path: "dashboard",
    loadChildren: () =>
      import("./school/pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./school/pages/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: "staff",
    loadChildren: () =>
      import("./school/pages/staff/staff.module").then(
        (m) => m.StaffModule
      ),
  },
  {
    path: "lms",
    loadChildren: () =>
      import("./school/pages/lms/lms.module").then(
        (m) => m.LmsModule
      ),
  },
  {
    path: "administration",
    loadChildren: () =>
      import("./school/pages/administration/administration.module").then(
        (m) => m.administrationModule
      ),
  },
  {
    path: "inbox",
    loadChildren: () =>
      import("./school/pages/inbox/inbox.module").then(
        (m) => m.InboxModule
      ),
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
