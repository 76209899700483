import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-clear-filters",
  templateUrl: "./clear-filters.component.html",
  styleUrls: ["./clear-filters.component.scss"],
})
export class ClearFiltersComponent implements OnInit {
  filterVal = "Date"
  constructor() {}

  ngOnInit() {}
  changeFilter(val) {
    this.filterVal = val;
  }
}
